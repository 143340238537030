<template>
    <a :href="href" :target="needToOpenNewTab ? '_blank' : undefined">
        <v-icon :icon="icon"/>
        <p>{{ label }}</p>
    </a>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';
import {isNotUndefined} from "@/utils/Types";

@Component({})
export default class RichHypertextLink extends Vue {

    @Prop
    private label?: string;

    @Prop
    private icon?: string;

    @Prop
    private href?: string;
    @Prop
    private newTab?: boolean;

    private needToOpenNewTab: boolean = false;

    mounted() {
        this.$watch(() => this.newTab,
            () => {
                this.needToOpenNewTab = isNotUndefined(this.newTab);
            });

        this.needToOpenNewTab = isNotUndefined(this.newTab);
    }

}
</script>

<style scoped>

a {
    display: flex;
    align-items: end;
    gap: 5px;

    opacity: 0.8;
    transition: opacity 0.2s;

    height: 30px;
    text-decoration: none;
    color: var(--third-color);
}

a:hover {
    opacity: 1;
}

a p {
    border-bottom: solid 1px;
}

</style>