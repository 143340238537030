import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rich_paragraph = _resolveComponent("rich-paragraph")!

  return (_openBlock(), _createBlock(_component_rich_paragraph, {
    title: _ctx.$t('message.home.QA.IP.answer.title'),
    text: _ctx.$t('message.home.QA.IP.answer.text'),
    "image-src": _ctx.imgSrc
  }, null, 8, ["title", "text", "image-src"]))
}