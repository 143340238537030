import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-section" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "title-buttons-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_image_label = _resolveComponent("image-label")!
  const _component_iaca_box_specs = _resolveComponent("iaca-box-specs")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_iaca_box_image_carousel = _resolveComponent("iaca-box-image-carousel")!
  const _component_big_button = _resolveComponent("big-button")!
  const _component_rich_hypertext_link = _resolveComponent("rich-hypertext-link")!
  const _component_feature_tile_container = _resolveComponent("feature-tile-container")!
  const _component_iaca_box_q_a_container = _resolveComponent("iaca-box-q-a-container")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_nav_bar),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("h1", null, "IACA BOX", -1)),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('message.home.title')), 1),
        _createVNode(_component_image_label, {
          class: "mt-10 mb-5",
          "image-path": "@/assets/img/electronic_components_raspberry_logo_pi_open_source_tecnology_icon_228456.png",
          label: "Box"
        }),
        _createVNode(_component_iaca_box_specs, { class: "specs-container" }),
        _createVNode(_component_v_alert, {
          value: true,
          icon: "mdi-account-hard-hat",
          class: "mt-10 mb-5"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('message.home.dev')), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_iaca_box_image_carousel, { class: "title-img" }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_big_button, {
          href: "#more",
          class: "mb-5",
          label: _ctx.$t('message.home.btn.more'),
          variant: "primary"
        }, null, 8, ["label"]),
        _createVNode(_component_big_button, {
          href: "https://shop.iaca-electronique.com",
          class: "mb-5",
          label: _ctx.$t('message.home.btn.buy'),
          variant: "secondary"
        }, null, 8, ["label"]),
        _createVNode(_component_rich_hypertext_link, {
          href: "https://trello.com/b/ecjGSfCX/iaca-box",
          "new-tab": "",
          class: "mb-5",
          label: _ctx.$t('message.home.btn.trello'),
          icon: "mdi-trello"
        }, null, 8, ["label"])
      ])
    ]),
    _createVNode(_component_feature_tile_container, {
      id: "more",
      class: "features-container"
    }),
    _createVNode(_component_iaca_box_q_a_container),
    _createVNode(_component_Footer)
  ], 64))
}