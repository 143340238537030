<template>
    <div class="d-flex justify-start align-center ga-2">
        <v-icon :icon="icon"/>
        <p class="name align-self-end">{{ name }}</p>
        <p class="details align-self-end">{{ details }}</p>
    </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class SpecLine extends Vue {

    @Prop
    private name? : string;

    @Prop
    private details? : string;

    @Prop
    private icon? : string;

}
</script>

<style scoped>

.name {
    font-size: 1.2em;
}

.details {
    font-style: italic;
    opacity: 0.8;
    font-size: 0.9em;
}

</style>