<template>
    <a :href="href"
       :class="variant"
       :target="needToOpenNewTab ? '_blank' : undefined"
    >
        {{ label }}
    </a>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';
import {isNotUndefined} from "@/utils/Types";

@Component({})
export default class BigButton extends Vue {
    @Prop
    private label?: string;
    @Prop
    private variant: 'primary' | 'secondary' = 'primary';
    @Prop
    private href?: string;
    @Prop
    private newTab?: boolean;

    private needToOpenNewTab: boolean = false;

    mounted() {
        this.$watch(() => this.newTab,
            () => {
                this.needToOpenNewTab = isNotUndefined(this.newTab);
            });

        this.needToOpenNewTab = isNotUndefined(this.newTab);
    }
}
</script>

<style scoped>

a {
    height: 50px;
    min-width: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    box-shadow: 2px 2px 2px 1px black;

    padding: 0 20px;
    font-size: 1.2em;

    transition: transform 0.2s;

    text-decoration: none;
}

a.primary {
    background-color: var(--primary-color);
    color: var(--third-color);
}

a.secondary {
    background-color: var(--third-color);
    color: var(--primary-color);
}

a:hover {
    transform: scale(1.02);
}

</style>