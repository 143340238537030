<template>
    <main>
        <h2>{{ title }}</h2>
        <img :src="src" alt="image"/>
    </main>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class TitledImage extends Vue {
    @Prop
    private title?: string;

    @Prop
    private src?: string;
}
</script>

<style scoped>

main {
    background-color: var(--secondary-color);
    display: grid;
    grid-template-rows: auto auto;
    flex-direction: column;
    align-items: start;
    padding: 20px;
    width: 100%;
}

main h2 {
    color: var(--third-color);
    text-align: center;
    font-size: 1.2em;
    font-weight: normal;
}

main img {
    margin-top: 20px;
    width: 95%;
    align-self: center;
}

</style>