<template>

    <section>
        <img src="@/assets/img/electronic_components_raspberry_logo_pi_open_source_tecnology_icon_228456.png" :alt="imageAlt"/>
        <h3>{{ $t('message.raspberry-image-label.raspberry') }}</h3>
    </section>

</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';

@Component({})
export default class RaspberryImageLabel extends Vue {

}
</script>


<style scoped>

section {
    display: flex;
    align-items: end;
}

section img {
    height: 40px;
    filter: invert() opacity(0.8);
}

section h3 {
    opacity: 0.8;
}
</style>