<template>
    <router-view/>
</template>

<script lang="ts">


import {Component, Vue} from "vue-facing-decorator";

@Component
export default class App extends Vue {
}
</script>

<style>
@import "styles/global.css";

* {
    font-family: "Iaca Arial", serif;
}

html {
    scroll-behavior: smooth;
}
</style>