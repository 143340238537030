<template>

    <header ref="navBar">

        <section>
            <img src="@/assets/img/Logo%20IACA_grey.png" alt="Company logo" @click="toHome()" class="cursor-pointer"/>
        </section>


        <section>
            <a href="https://iaca-electronique.com/html/contact.html" class="active" target="_blank">Contact</a>
            <language-selector/>
        </section>
    </header>

</template>

<script lang="ts">
import {Component, Vue} from 'vue-facing-decorator';
import LanguageSelector from "@/components/business/LanguageSelector.vue";

@Component({
    components: {LanguageSelector}
})
export default class NavBar extends Vue {
    private lastScrollY: number = 0;
    private scrollBuffer: number[] = [];
    private lastScrollTime: number = 0;

    private listener: (ev: Event) => void = () => {
    };


    mounted() {
        this.listener = (ev: Event) => {

            this.scrollBuffer.push(window.scrollY);

            if(new Date().getTime() - this.lastScrollTime > 50){
                this.scrollBuffer = [];
            }

            this.lastScrollTime = new Date().getTime();

            if (this.scrollBuffer.length > 3 || window.scrollY < 20) {
                const scrollY = NavBar.getAverage(this.scrollBuffer);
                const mode = this.getMode(scrollY, this.lastScrollY);

                this.applyMode(mode);

                this.lastScrollY = scrollY;
                this.scrollBuffer = [];
            }
        }

        document.addEventListener('scroll', this.listener);
    }

    private static getAverage(array: number[]): number {
        let sum = array.reduce((a, b) => a + b, 0);
        return sum / array.length;
    }

    private getMode(scrollY: number, lastScrollY: number): Mode {
        let mode: Mode = Mode.PERMANENT;

        if (scrollY > 10 && scrollY < 400) {
            mode = Mode.STICKY_NO_ANIM;
        } else if (scrollY > 400 && scrollY > lastScrollY) {
            mode = Mode.HIDE;
        } else if (scrollY > 400 && scrollY < lastScrollY) {
            mode = Mode.STICKY;
        }

        return mode;
    }


    private applyMode(mode: Mode) {
        const dom = this.$refs.navBar as HTMLElement;

        switch (mode) {
            case Mode.PERMANENT: {
                dom.classList.add('permanent');
                dom.classList.remove('sticky', 'hidden', 'no-anim');
                break;
            }
            case Mode.HIDE: {
                dom.classList.add('hidden');
                dom.classList.remove('sticky', 'permanent', 'no-anim');
                break;
            }
            case Mode.STICKY: {
                dom.classList.add('sticky');
                dom.classList.remove('hidden', 'permanent', 'no-anim');
                break;
            }
            case Mode.STICKY_NO_ANIM: {
                dom.classList.add('sticky');
                dom.classList.add('no-anim');
                dom.classList.remove('hidden', 'permanent');
                break;
            }
        }
    }

    private hideAfterTime(){
        setTimeout(()=>{
            const now = new Date().getTime();

            if(now - this.lastScrollTime > 100 && window.scrollY > 200){
                this.applyMode(Mode.HIDE)
            }
        }, 1000)
    }

    private toHome(){
        this.$router.push({name:'home'});
    }

    unmounted() {
        document.removeEventListener('scroll', this.listener);
    }
}

enum Mode {
    STICKY,
    HIDE,
    PERMANENT,
    STICKY_NO_ANIM
}
</script>

<style scoped>

section {
    display: flex;
    align-items: center;
}

header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 100;

    transition: background 500ms;
    transition: box-shadow 500ms;
}

header.sticky,
header.hidden {
    position: fixed;
    background-color: var(--secondary-color);
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, .25);
}
header.sticky {
    animation: show 200ms linear forwards;
}

header.sticky.no-anim {
    animation: none;
}

header.hidden {
    animation: hide 500ms linear forwards;
}

@keyframes hide {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100px);
    }
}

@keyframes show {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0);
    }
}


header h1 {
    color: var(--third-color);
}

header section {
    margin: 5px;
}

header section a {
    margin: 0 10px;
    text-decoration: none;
}

a.active {
    color: var(--third-color);
}

a {
    color: var(--third-color);
}

header section img {
    height: 35px;
    filter: invert(100%) opacity(0.5);
}

@media screen and (max-width: 500px) {
    header h1 {
        font-size: 0.8em;
    }

    header section a {
        font-size: 0.6em;
    }
}
</style>