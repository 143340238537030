<template>
    <main>
        <titled-image class="container" :title="$t('message.environment.services')" src="/img/services.png"/>
    </main>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import TitledImage from "@/components/common/TitledImage.vue";

@Component({
    components: {TitledImage}
})
export default class IacaBoxServices extends Vue {

}
</script>

<style scoped>
main {
    width: 700px;
    height: 800px;
}

@media screen and (max-width: 1400px) {
    main {
        width: 80vw;
        height: auto;
    }
}

@media screen and (max-width: 1000px) {
    main {
        width: 90vw;
        height: auto;
    }
}

</style>