import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rich_paragraph = _resolveComponent("rich-paragraph")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_rich_paragraph, {
      title: _ctx.$t('message.environment.title'),
      text: _ctx.$t('message.environment.services'),
      "image-src": "/img/environment.png"
    }, null, 8, ["title", "text"])
  ]))
}