import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_titled_image = _resolveComponent("titled-image")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_titled_image, {
      class: "container",
      title: _ctx.$t('message.environment.services'),
      src: "/img/services.png"
    }, null, 8, ["title"])
  ]))
}