<template>
    <rich-paragraph
        :title="$t('message.home.QA.custom.answer.title')"
        :text="$t('message.home.QA.custom.answer.text')"
        :image-src="imgSrc"
    />
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import RichParagraph from "@/components/common/RichParagraph.vue";

@Component({
    components: {RichParagraph}
})
export default class IacaBoxAddCustomDevice extends Vue {
    private imgSrc: string = '/img/custom-fr.png';

    private mounted() {
        this.$watch(()=> this.$i18n.locale,
            () => {
                this.loadUi(this.$i18n.locale);
            });

        this.loadUi(this.$i18n.locale);
    }

    private loadUi(local : string){
        if(local === 'fr'){
            this.imgSrc = '/img/custom-fr.png';
        }else
            this.imgSrc = '/img/custom-en.png';
    }
}
</script>

<style scoped>

</style>