import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "element" }
const _hoisted_2 = { class: "element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spec_line = _resolveComponent("spec-line")!
  const _component_spec_container = _resolveComponent("spec-container")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_spec_container, {
        label: _ctx.$t('message.home.specs.label.connected')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_spec_line, {
            name: "Wifi",
            details: "2.4 GHz, 5GHz IEEE 802.11 (CM4)",
            icon: "mdi-wifi"
          }),
          _createVNode(_component_spec_line, {
            name: "Bluetooth",
            details: "5.0, BLE (CM4)",
            icon: "mdi-bluetooth"
          })
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_spec_container, {
        label: _ctx.$t('message.home.specs.label.ground')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_spec_line, {
            name: "CAN",
            icon: "mdi-cable-data"
          }),
          _createVNode(_component_spec_line, {
            name: "USB",
            details: "2xUSB 2.0 & 2xUSB 3.0",
            icon: "mdi-usb"
          }),
          _createVNode(_component_spec_line, {
            name: "RS 485",
            icon: "mdi-serial-port"
          })
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_spec_container, {
        label: _ctx.$t('message.home.specs.label.protection')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_spec_line, {
            name: _ctx.$t('message.home.specs.watchdog'),
            icon: "mdi-power-settings"
          }, null, 8, ["name"]),
          _createVNode(_component_spec_line, {
            name: _ctx.$t('message.home.specs.os'),
            icon: "mdi-shield"
          }, null, 8, ["name"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_spec_container, {
        label: _ctx.$t('message.home.specs.label.display')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_spec_line, {
            name: "HDMI",
            details: "2xHDMI @ 4Kp60",
            icon: "mdi-hdmi-port"
          })
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_spec_container, {
        label: _ctx.$t('message.home.specs.label.compute')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_spec_line, {
            name: "Cortex-A72",
            details: "64bits SoC @ 1.5GHz",
            icon: "mdi-cpu-64-bit"
          }),
          _createVNode(_component_spec_line, {
            name: "LPDDR4",
            details: "1 / 2 / 4 / 8 GB",
            icon: "mdi-memory"
          }),
          _createVNode(_component_spec_line, {
            name: "GPU",
            details: "VideoCore VI, 500MHz",
            icon: "mdi-expansion-card"
          })
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_spec_container, {
        label: _ctx.$t('message.home.specs.label.storage')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_spec_line, {
            name: "eMMC",
            details: _ctx.$t('message.home.specs.emmc'),
            icon: "mdi-memory"
          }, null, 8, ["details"]),
          _createVNode(_component_spec_line, {
            name: "NVMe",
            details: "PCIe Gen 3",
            icon: "mdi-memory"
          })
        ]),
        _: 1
      }, 8, ["label"])
    ])
  ]))
}