import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "qa-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_q_a_expansion_panel = _resolveComponent("q-a-expansion-panel")!
  const _component_iaca_box_add_custom_device = _resolveComponent("iaca-box-add-custom-device")!
  const _component_iaca_box_i_p = _resolveComponent("iaca-box-i-p")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('message.home.QA.title')), 1),
    _createVNode(_component_q_a_expansion_panel, null, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_chip, { class: "primary mr-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('message.home.QA.training.tag')), 1)
            ]),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('message.home.QA.training.title')), 1)
        ])
      ]),
      content: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('message.home.QA.training.answer')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_q_a_expansion_panel, null, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_chip, { class: "primary mr-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('message.home.QA.custom.tag')), 1)
            ]),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('message.home.QA.custom.title')), 1)
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_iaca_box_add_custom_device)
      ]),
      _: 1
    }),
    _createVNode(_component_q_a_expansion_panel, null, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_chip, { class: "primary mr-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('message.home.QA.IP.tag')), 1)
            ]),
            _: 1
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('message.home.QA.IP.title')), 1)
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_iaca_box_i_p)
      ]),
      _: 1
    })
  ]))
}