<template>
    <nav-bar/>
    <section class="main-section">

        <div class="title-container">
            <h1>IACA BOX</h1>
            <h2>{{ $t('message.home.title') }}</h2>
            <image-label class="mt-10 mb-5"
                         image-path="@/assets/img/electronic_components_raspberry_logo_pi_open_source_tecnology_icon_228456.png"
                         label="Box"/>

            <iaca-box-specs class="specs-container"/>
            <v-alert :value="true" icon="mdi-account-hard-hat" class="mt-10 mb-5">{{ $t('message.home.dev') }}</v-alert>

        </div>

        <iaca-box-image-carousel class="title-img"/>

        <div class="title-buttons-container">
            <big-button href="#more" class="mb-5" :label="$t('message.home.btn.more')" variant="primary"/>
            <big-button href="https://shop.iaca-electronique.com" class="mb-5" :label="$t('message.home.btn.buy')" variant="secondary"/>

            <!--            <v-tooltip text="En cours de développement" location="top">-->
            <!--                <template v-slot:activator="{ props }">-->
            <!--                    <rich-hypertext-link-->
            <!--                        v-bind="props"-->
            <!--                        class="mb-5"-->
            <!--                        :label="$t('message.home.btn.doc')" -->
            <!--                        icon="mdi-book-open-blank-variant"-->
            <!--                    />-->
            <!--                </template>-->
            <!--            </v-tooltip>-->

            <!--            <rich-hypertext-link class="mb-5" :label="$t('message.home.btn.service')" icon="mdi-toy-brick-plus"/>-->
            <rich-hypertext-link
                href="https://trello.com/b/ecjGSfCX/iaca-box"
                new-tab
                class="mb-5"
                :label="$t('message.home.btn.trello')"
                icon="mdi-trello"/>
        </div>
    </section>

    <feature-tile-container id="more" class="features-container"/>


    <iaca-box-q-a-container/>

    <Footer/>

</template>

<script lang="ts">


import {Component, Vue} from "vue-facing-decorator";
import NavBar from "@/components/business/NavBar.vue";
import RaspberryImageLabel from "@/components/business/RaspberryImageLabel.vue";
import BigButton from "@/components/common/BigButton.vue";
import SpecContainer from "@/components/common/SpecContainer.vue";
import SpecLine from "@/components/common/SpecLine.vue";
import IacaBoxSpecs from "@/components/business/IacaBoxSpecs.vue";
import RichHypertextLink from "@/components/common/RichHypertextLink.vue";
import FeatureTileContainer from "@/components/business/FeatureTileContainer.vue";
import IacaBoxEnvironment from "@/components/business/IacaBoxEnvironment.vue";
import IacaBoxServices from "@/components/business/QA/IacaBoxServices.vue";
import IacaBoxImageCarousel from "@/components/business/IacaBoxImageCarousel.vue";
import IacaBoxQAContainer from "@/components/business/IacaBoxQAContainer.vue";
import Footer from "@/components/business/Footer.vue";

@Component({
    components: {
        Footer,
        IacaBoxQAContainer,
        IacaBoxImageCarousel,
        IacaBoxServices,
        IacaBoxEnvironment,
        FeatureTileContainer,
        RichHypertextLink,
        IacaBoxSpecs, SpecLine, SpecContainer, BigButton, ImageLabel: RaspberryImageLabel, NavBar
    }
})

export default class HomeView extends Vue {


    async mounted(): Promise<void> {

    }


}
</script>

<style scoped>

.main-section {
    color: var(--third-color);

    width: 100vw;

    background-color: var(--secondary-color);

    display: grid;
    grid-template-columns: 60px calc(50% - 60px) 50%;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;

}

.main-section .title-container {
    grid-column-start: 2;

    margin-top: 60px;

    display: flex;
    flex-direction: column;
}

.title-buttons-container {
    grid-column-start: 2;
    grid-column-end: 3;

    display: flex;
    flex-wrap: wrap;
    align-items: end;

    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;

    gap: 10px;
}

.main-section h1 {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 6em;
}

.main-section h2 {
    font-weight: normal;
    font-size: 2em;
}

.main-section .title-img {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.main-section .btn {
    color: white;
    background-color: var(--primary-color);
    padding: 15px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 5px;

    width: fit-content;
    cursor: pointer;
}

.specs-container {
    margin-bottom: 60px;
}

.features-container {
    padding-top: 40px;
    padding-bottom: 20px;
}

@media screen and (max-width: 1000px) {
    .main-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 50px;
    }
}


@media screen and (min-width: 1001px) and (max-width: 1500px) {
    .main-section h1 {
        font-size: 4em;
    }
}

@media screen and (min-width: 500px) and (max-width: 1000px) {
    .main-section h1 {
        font-size: 3em;
    }

    .main-section img.title-img {
        width: 50%;
    }
}

@media screen and (max-width: 500px) {
    .main-section {
        padding-top: 20px;
        padding-left: 0;
    }


    .main-section .title-container {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        order: 2;
    }

    .main-section h1 {
        font-size: 2.5em;
        text-align: center;
        transform: translateY(0);
        margin-bottom: 20px;
    }

    .main-section h2 {
        font-size: 1.5em;
        text-align: center;
        transform: translateY(0);
        margin-bottom: 20px;
    }

    .main-section .title-img {
        transform: translateY(30px);
        order: 1;
    }

    .title-buttons-container {
        order: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .specs-container {
        margin-left: 20px;
    }
}

</style>