<template>
    <div class="container">
        <v-carousel
            height="100%"
            show-arrows="hover"
            hide-delimiter-background
            cover
        >
            <v-carousel-item
                src="/img/IB_face_1.png"
            />

            <v-carousel-item
                :src="spiderImgPath"
            />
        </v-carousel>
    </div>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';

const DEFAULT_SPIDER_IMAGE_PATH = '/img/spider-fr.png'

@Component({})
export default class IacaBoxImageCarousel extends Vue {

    private spiderImgPath = DEFAULT_SPIDER_IMAGE_PATH;

    private mounted() {
        this.$watch(() => this.$i18n.locale,
            () => {
                this.loadUiFromLocal(this.$i18n.locale);
            });

        this.loadUiFromLocal(this.$i18n.locale);
    }

    private loadUiFromLocal(local: string) {
        if (local === 'en') {
            this.spiderImgPath = '/img/spider-en.png'
        } else {
            this.spiderImgPath = DEFAULT_SPIDER_IMAGE_PATH;
        }
    }

}
</script>


<style scoped>
.container {
    height: 100%;
    width: 100%;
}

</style>