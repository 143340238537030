import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.href,
    target: _ctx.needToOpenNewTab ? '_blank' : undefined
  }, [
    _createVNode(_component_v_icon, { icon: _ctx.icon }, null, 8, ["icon"]),
    _createElementVNode("p", null, _toDisplayString(_ctx.label), 1)
  ], 8, _hoisted_1))
}