<template>

    <main>
        <section class="text">
            
            <h2>{{ title }}</h2>
            <p>{{ text }}</p>
            
        </section>
        
        <section class="image">
            <img :src="imageSrc" alt="image"/>
        </section>
    </main>
    
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class RichParagraph extends Vue {


    @Prop
    private title?: string;
    @Prop
    private text?: string;
    @Prop
    private imageSrc?: string;
    
}
</script>

<style scoped>

main {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;

    width: 100%;
}

main section.text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 90%;
}

main section.text h2 {
    margin-bottom: 20px;
}

main section.image img {
    max-width: 100%;
}

@media screen and (max-width: 600px) {
    main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

</style>