<template>
    <main>
        <feature-tile :title="$t('message.features.os.title')" :text="$t('message.features.os.text')" icon="mdi-linux"
                      variant="secondary"/>
        <feature-tile :title="$t('message.features.industrial.title')" :text="$t('message.features.industrial.text')" icon="mdi-factory"
                      variant="primary"/>
        <feature-tile :title="$t('message.features.support.title')" :text="$t('message.features.support.text')" icon="mdi-account-group-outline"
                      variant="secondary"/>
        <feature-tile :title="$t('message.features.custom.title')" :text="$t('message.features.custom.text')" icon="mdi-toy-brick-plus-outline"
                      variant="primary"/>
        <feature-tile :title="$t('message.features.material.title')" :text="$t('message.features.material.text')" icon="mdi-toy-brick-plus-outline"
                      variant="secondary"/>
    </main>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import FeatureTile from "@/components/common/FeatureTile.vue";

@Component({
    components: {FeatureTile}
})
export default class FeatureTileContainer extends Vue {

}
</script>


<style scoped>

main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    gap: 10px;

    background-color: var(--third-color);
}

@media screen and (max-width: 450px) {
    flex-direction: column;
    justify-content: start;
}

</style>