import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feature_tile = _resolveComponent("feature-tile")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_feature_tile, {
      title: _ctx.$t('message.features.os.title'),
      text: _ctx.$t('message.features.os.text'),
      icon: "mdi-linux",
      variant: "secondary"
    }, null, 8, ["title", "text"]),
    _createVNode(_component_feature_tile, {
      title: _ctx.$t('message.features.industrial.title'),
      text: _ctx.$t('message.features.industrial.text'),
      icon: "mdi-factory",
      variant: "primary"
    }, null, 8, ["title", "text"]),
    _createVNode(_component_feature_tile, {
      title: _ctx.$t('message.features.support.title'),
      text: _ctx.$t('message.features.support.text'),
      icon: "mdi-account-group-outline",
      variant: "secondary"
    }, null, 8, ["title", "text"]),
    _createVNode(_component_feature_tile, {
      title: _ctx.$t('message.features.custom.title'),
      text: _ctx.$t('message.features.custom.text'),
      icon: "mdi-toy-brick-plus-outline",
      variant: "primary"
    }, null, 8, ["title", "text"]),
    _createVNode(_component_feature_tile, {
      title: _ctx.$t('message.features.material.title'),
      text: _ctx.$t('message.features.material.text'),
      icon: "mdi-toy-brick-plus-outline",
      variant: "secondary"
    }, null, 8, ["title", "text"])
  ]))
}