import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/Logo IACA_grey.png'


const _hoisted_1 = { ref: "navBar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_language_selector = _resolveComponent("language-selector")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("section", null, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Company logo",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toHome())),
        class: "cursor-pointer"
      })
    ]),
    _createElementVNode("section", null, [
      _cache[1] || (_cache[1] = _createElementVNode("a", {
        href: "https://iaca-electronique.com/html/contact.html",
        class: "active",
        target: "_blank"
      }, "Contact", -1)),
      _createVNode(_component_language_selector)
    ])
  ], 512))
}