import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import fr from '@/locales/fr.json';
import Session from "@/Session";

const messages = {
    en,
    fr,
};

const i18n = createI18n({
    locale: Session.getLocal('fr'),
    fallbackLocale: 'fr',
    messages
});

export default i18n;