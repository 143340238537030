import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-content-container" }
const _hoisted_2 = { class: "sub-content-container" }
const _hoisted_3 = {
  class: "mentions discret",
  href: "https://iaca-electronique.com/html/mentionlegales.html"
}
const _hoisted_4 = { class: "author-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_big_button = _resolveComponent("big-button")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("span", null, "IACA Box", -1)),
        _createVNode(_component_big_button, {
          label: _ctx.$t('message.footer.contact'),
          variant: "secondary",
          href: "https://iaca-electronique.com/html/contact.html",
          "new-tab": ""
        }, null, 8, ["label"])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "separator" }, null, -1)),
      _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t('message.footer.legal')), 1)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("span", null, [
        _createTextVNode(_toDisplayString(_ctx.$t('message.footer.author')) + " ", 1),
        _cache[2] || (_cache[2] = _createElementVNode("a", { href: "https://iaca-electronique.com" }, "IACA Electronique", -1))
      ])
    ])
  ]))
}