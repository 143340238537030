import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imageSrc,
        alt: "image"
      }, null, 8, _hoisted_3)
    ])
  ]))
}