<template>
    <v-menu theme="dark">
        <template v-slot:activator="{ props }">
            <img
                v-bind="props"
                alt="Language selector"
                class="button cursor-pointer opacity-80"
                :src="ui.languageImgPath"
            />
        </template>

        <v-list>
            <v-list-item @click="onChange('fr')">
                <v-list-item-title>
                    <v-btn>
                        <img
                            v-bind="props"
                            alt="Language selector"
                            class="button"
                            src="@/assets/img/france.png"
                        />Français
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item @click="onChange('en')">
                <v-list-item-title>
                    <v-btn>
                        <img
                            v-bind="props"
                            alt="Language selector"
                            class="button"
                            src="@/assets/img/uk.png"
                        />
                        English

                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import Session from "@/Session";

const DEFAULT_LANGUAGE_IMAGE_PATH = '/img/france.png'

@Component({})
export default class LanguageSelector extends Vue {


    private readonly ui = {
        languageImgPath : DEFAULT_LANGUAGE_IMAGE_PATH
    }

    private mounted() {
        this.$watch(()=>this.$i18n.locale, () => {
            this.loadUi();
        });

        this.loadUi();
    }

    private loadUi() {
        switch (this.$i18n.locale) {
            case 'en':
                this.ui.languageImgPath = '/img/uk.png';
                break;
            default:
                this.ui.languageImgPath = DEFAULT_LANGUAGE_IMAGE_PATH;
        }
    }

    private onChange(local: string) {
        this.$i18n.locale = local;
        Session.setLocal(local);
    }

}
</script>

<style scoped>

.button {
    height: 20px;
}

</style>