<template>
    <v-expansion-panels vark-model="compact">
        <v-expansion-panel>
            <v-expansion-panel-title class="title">
                <slot name="title"/>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="content">
                <slot name="content"/>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import IacaBoxServices from "@/components/business/QA/IacaBoxServices.vue";

@Component({
    components: {IacaBoxServices}
})
export default class QAExpansionPanel extends Vue {

}
</script>

<style scoped>

.title {
    color: white;
    background-color: var(--primary-color);
    font-size: 1.2em;
}

.content {
    border: var(--primary-color) solid 2px;
    background-color: var(--secondary-color);
    color: var(--third-color);
}

</style>