import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/electronic_components_raspberry_logo_pi_open_source_tecnology_icon_228456.png'


const _hoisted_1 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("img", {
      src: _imports_0,
      alt: _ctx.imageAlt
    }, null, 8, _hoisted_1),
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('message.raspberry-image-label.raspberry')), 1)
  ]))
}