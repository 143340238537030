<template>
    <main>
        <div class="element">
            <spec-container :label="$t('message.home.specs.label.connected')">
                <spec-line name="Wifi" details="2.4 GHz, 5GHz IEEE 802.11 (CM4)" icon="mdi-wifi"/>
                <spec-line name="Bluetooth" details="5.0, BLE (CM4)" icon="mdi-bluetooth"/>
            </spec-container>

            <spec-container :label="$t('message.home.specs.label.ground')">
                <spec-line name="CAN" icon="mdi-cable-data"/>
                <spec-line name="USB" details="2xUSB 2.0 & 2xUSB 3.0" icon="mdi-usb"/>
                <spec-line name="RS 485" icon="mdi-serial-port"/>
            </spec-container>

            <spec-container :label="$t('message.home.specs.label.protection')">
                <spec-line :name="$t('message.home.specs.watchdog')" icon="mdi-power-settings"/>
                <spec-line :name="$t('message.home.specs.os')" icon="mdi-shield"/>
            </spec-container>
        </div>

        <div class="element">
            <spec-container :label="$t('message.home.specs.label.display')">
                <spec-line name="HDMI" details="2xHDMI @ 4Kp60" icon="mdi-hdmi-port"/>
            </spec-container>

            <spec-container :label="$t('message.home.specs.label.compute')">
                <spec-line name="Cortex-A72" details="64bits SoC @ 1.5GHz" icon="mdi-cpu-64-bit"/>
                <spec-line name="LPDDR4" details="1 / 2 / 4 / 8 GB" icon="mdi-memory"/>
                <spec-line name="GPU" details="VideoCore VI, 500MHz" icon="mdi-expansion-card"/>
            </spec-container>

            <spec-container :label="$t('message.home.specs.label.storage')">
                <spec-line name="eMMC" :details="$t('message.home.specs.emmc')" icon="mdi-memory"/>
                <spec-line name="NVMe" details="PCIe Gen 3" icon="mdi-memory"/>
            </spec-container>
        </div>
    </main>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import SpecLine from "@/components/common/SpecLine.vue";
import SpecContainer from "@/components/common/SpecContainer.vue";

@Component({
    components: {SpecContainer, SpecLine}
})
export default class IacaBoxSpecs extends Vue {

}
</script>

<style scoped>
main {
    display: flex;
    flex-wrap: wrap;
}

.element {
    width: 320px;
}
</style>