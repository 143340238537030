import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createBlock(_component_v_expansion_panels, { "vark-model": "compact" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_expansion_panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panel_title, { class: "title" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title", {}, undefined, true)
            ]),
            _: 3
          }),
          _createVNode(_component_v_expansion_panel_text, { class: "content" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}