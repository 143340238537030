import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_carousel, {
      height: "100%",
      "show-arrows": "hover",
      "hide-delimiter-background": "",
      cover: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_carousel_item, { src: "/img/IB_face_1.png" }),
        _createVNode(_component_v_carousel_item, { src: _ctx.spiderImgPath }, null, 8, ["src"])
      ]),
      _: 1
    })
  ]))
}