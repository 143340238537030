<template>
    <main class="qa-container">
        <h3>{{ $t('message.home.QA.title') }}</h3>

        <q-a-expansion-panel>
            <template v-slot:title>
                <div class="title">
                    <v-chip class="primary mr-2">{{ $t('message.home.QA.training.tag') }}</v-chip>
                    {{ $t('message.home.QA.training.title') }}
                </div>
            </template>
            <template v-slot:content>
                {{ $t('message.home.QA.training.answer') }}
            </template>
        </q-a-expansion-panel>

        <q-a-expansion-panel>
            <template v-slot:title>
                <div class="title">
                    <v-chip class="primary mr-2">{{ $t('message.home.QA.custom.tag') }}</v-chip>
                    {{ $t('message.home.QA.custom.title') }}
                </div>
            </template>
            <template v-slot:content>
                <iaca-box-add-custom-device/>
            </template>
        </q-a-expansion-panel>

        <q-a-expansion-panel>
            <template v-slot:title>
                <div class="title">
                    <v-chip class="primary mr-2">{{ $t('message.home.QA.IP.tag') }}</v-chip>
                    {{ $t('message.home.QA.IP.title') }}
                </div>
            </template>
            <template v-slot:content>
                <iaca-box-i-p/>
            </template>
        </q-a-expansion-panel>
    </main>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import QAExpansionPanel from "@/components/business/QA/QAExpansionPanel.vue";
import IacaBoxServices from "@/components/business/QA/IacaBoxServices.vue";
import IacaBoxAddCustomDevice from "@/components/business/QA/IacaBoxAddCustomDevice.vue";
import IacaBoxIP from "@/components/business/QA/IacaBoxIP.vue";


@Component({
    components: {IacaBoxIP, IacaBoxAddCustomDevice, IacaBoxServices, QAExpansionPanel}

})
export default class IacaBoxQAContainer extends Vue {

}
</script>

<style scoped>

main.qa-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    min-height: 80vh;
    background-color: var(--secondary-color);
    padding: 20px 10%;
}

main h3 {
    font-size: 2em;
    text-align: center;
    color: var(--third-color);
    margin-bottom: 30px;
    text-shadow: 1px 1px 5px black;
}

.primary {
    color: var(--secondary-color);
    background-color: var(--third-color);
}

.title {
    display: flex;
    align-items: center;
}


@media screen and (max-width: 700px) {
    .title {
        flex-direction: column;

        gap: 5px;
    }
}
</style>