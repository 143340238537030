import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("img", {
      src: _ctx.src,
      alt: "image"
    }, null, 8, _hoisted_1)
  ]))
}