<template>
    <main>
        <rich-paragraph
            :title="$t('message.environment.title')"
            :text="$t('message.environment.services')"
            image-src="/img/environment.png"
        />
    </main>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import TitledImage from "@/components/common/TitledImage.vue";
import RichParagraph from "@/components/common/RichParagraph.vue";

@Component({
    components: {RichParagraph, TitledImage}
})
export default class IacaBoxEnvironment extends Vue {

}
</script>

<style scoped>


</style>