<template>
    <section class="mt-3">
        <p>{{ label }}</p>
        <slot name="default">

        </slot>
    </section>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class SpecContainer extends Vue {

    @Prop
    private label?: string;

}
</script>

<style scoped>

p {
    opacity: 0.8;
    font-style: italic;
}

</style>