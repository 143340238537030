import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/france.png'
import _imports_1 from '@/assets/img/uk.png'


const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, { theme: "dark" }, {
    activator: _withCtx(({ props }) => [
      _createElementVNode("img", _mergeProps(props, {
        alt: "Language selector",
        class: "button cursor-pointer opacity-80",
        src: _ctx.ui.languageImgPath
      }), null, 16, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange('fr')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, null, {
                    default: _withCtx(() => [
                      _createElementVNode("img", _mergeProps(_ctx.props, {
                        alt: "Language selector",
                        class: "button",
                        src: _imports_0
                      }), null, 16),
                      _cache[2] || (_cache[2] = _createTextVNode("Français "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_list_item, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange('en')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, null, {
                    default: _withCtx(() => [
                      _createElementVNode("img", _mergeProps(_ctx.props, {
                        alt: "Language selector",
                        class: "button",
                        src: _imports_1
                      }), null, 16),
                      _cache[3] || (_cache[3] = _createTextVNode(" English "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}