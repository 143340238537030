import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.href,
    class: _normalizeClass(_ctx.variant),
    target: _ctx.needToOpenNewTab ? '_blank' : undefined
  }, _toDisplayString(_ctx.label), 11, _hoisted_1))
}