import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.variant)
  }, [
    _createVNode(_component_v_icon, {
      size: "60",
      icon: _ctx.icon,
      color: "white"
    }, null, 8, ["icon"]),
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("h3", null, _toDisplayString(_ctx.text), 1)
  ], 2))
}