<template>
    <section :class="variant">
        <v-icon size="60" :icon="icon" color="white"/>
        <h2>{{ title }}</h2>
        <h3>{{ text }}</h3>
    </section>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class FeatureTile extends Vue {
    @Prop
    private icon? : string;

    @Prop
    private title? : string;

    @Prop
    private text? : string;

    @Prop
    private variant? : 'primary' | 'secondary' = 'primary';
}
</script>


<style scoped>

section {
    width: 200px;
    height: 260px;

    border-radius: 5px;

    color: var(--third-color);

    display: grid;
    grid-template-rows: 20% 25% 55%;
    align-items: start;
    justify-items: center;
    gap: 15px;

    padding: 10px;

    box-shadow: 2px 2px 10px 1px black;
}

section.primary {
    background-color: var(--primary-color);
}

section.secondary {
    background-color: var(--secondary-color);
}

section h2,h3 {
    text-align: center;
}
section h2 {
    font-size: 1.7em;
}


section h3 {
    font-size: 1em;
    font-weight: normal;
}

@media screen and (max-width: 450px)  {
    section {
        width: 100%;
    }
}

</style>