import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import i18n from './i18n';


import { createI18n } from 'vue-i18n';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'

import "@mdi/font/css/materialdesignicons.css";



const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    directives
})

createApp(App).use(i18n).use(router).use(vuetify).mount('#app')
