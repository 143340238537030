<template>
    <footer>
        <section class="main-content-container">
            <div class="sub-content-container">
                <span>IACA Box</span>
                <big-button :label="$t('message.footer.contact')" variant="secondary" href="https://iaca-electronique.com/html/contact.html" new-tab/>
            </div>
            <div class="separator"/>
            <a class="mentions discret" href="https://iaca-electronique.com/html/mentionlegales.html">{{ $t('message.footer.legal') }}</a>
        </section>
        <section class="author-container">
            <span>{{ $t('message.footer.author') }} <a href="https://iaca-electronique.com">IACA Electronique</a></span>
        </section>
    </footer>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import BigButton from "@/components/common/BigButton.vue";

@Component({
    components: {BigButton}
})
export default class Footer extends Vue {

}
</script>

<style scoped>

footer {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: var(--primary-color);

    height: 200px;
}

.sub-content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    width: 80%;

    margin-top: 50px;
}

.sub-content-container span {
    color: var(--third-color);
    font-size: 2em;
    font-weight: bold;
}

.main-content-container a.mentions {
    color: var(--third-color);
    margin-bottom: 5px;
    text-decoration: none;
}

.discret {
    opacity: 0.5;
}

.separator {
    width: 80%;
    height: 2px;
    background-color: white;
    opacity: 0.5;

    margin-bottom: 40px;
}


.author-container {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
}

.author-container span, a {
    color: var(--third-color);
}

.author-container a {
    text-decoration: none;
    color: var(--primary-color);
}

@media screen and (max-width: 500px) {
    .sub-content-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 15px;
    }

    .sub-content-container span {
        font-size: 1.8em;
    }

    .separator {
        width: 50%;
        margin-bottom: 25px;
    }
}

</style>